.dashboard-container {
    display: flex;
    flex-direction: row;
}
.dashboard-body {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
}
td {
    color: #000000 !important;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
}