.sidebar {
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: sticky;
    background-color: #000000;
    overflow-y: scroll;
}
.sidebar-logo-container {
    display: 'flex';
    align-items: center;
}
.sidebar-logo-container img {
    width: 100%;
    padding: 2em;
}
.sidebar-container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
}
.sidebar-items {
    height: 100%;
    width: 100%;
    background-color: #000;
}
.sidebar-item {
    padding: 1.5em;
    padding-left: 2em;
    display: flex;
    color: #FFFFFF;
    list-style: none;
    text-decoration: none;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}
.sidebar-item:hover {
    background-color: #004B77;
}
.sidebar-item-active {
    padding: 1.5em;
    padding-left: 2em;
    display: flex;
    color: #FFFFFF;
    list-style: none;
    text-decoration: none;
    align-items: center;
    justify-content: flex-start;
    background-color: #004B77;
    cursor: pointer;
}
.sidebar-item-icon {
    width: 20px;
}
.sidebar-item-label {
    font-size: small;
    font-weight: 600;
    margin-left: 15px;
}
/* Footer */
.sidebar-footer {
    padding: 1.5em;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: #000;
}

tbody {
    border: 1px solid #7c7c7c17 !important;
    /* padding: 40px !important; */
}

.sidebar::-webkit-scrollbar {
    width: 5px;
  }
   
  .sidebar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  .sidebar::-webkit-scrollbar-thumb {
    background-color: #4073b2;
    outline: 1px solid slategrey;
  }