@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url(https://unpkg.com/@webpixels/css@1.1.5/dist/index.css);

/* Bootstrap Icons */
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css");
body{
    overflow-x: hidden;
}
.dashboard-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
}
.dashboard-content-container {
    margin: 1em;
    background-color: white;
    border: none;
    border-radius: 10px;
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap:25px;
    overflow: auto;
}
.dashboard-content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.dashboard-content-header h2 {
    margin-top: auto;
    margin-bottom: auto;
    color: black;
    font-size: xx-large;
    font-weight: 700;
}
.dashboard-content-search {
    font-weight: 400;
    line-height: 1.4em;
    letter-spacing: 0.009em;
    box-sizing: border-box;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    background-color: transparent;
    font-size: 0.875rem;
    border-radius: 0.375rem;
    border: 1px solid gray;
}
.dashboard-content-search:focus {
    outline: #2D83B5 auto 1px;
}
.dashboard-content-input {
    font-style: inherit;
    font-variant: inherit;
    font-weight: inherit;
    font-stretch: inherit;
    line-height: inherit;
    font-family: inherit;
    letter-spacing: inherit;
    border: 0px;
    box-sizing: content-box;
    background: none transparent;   
    margin: 0px;
    display: block;
    min-width: 0px;
    width: 100%;
    color: #000000;
    font-size: 0.75rem;
    padding: 0.625rem;
}
table {
    width: 100%;
    margin: auto;
}
th {
    text-align: left;
    padding: 12px 24px;
    background: transparent;
    box-shadow: none;
    color: #2D83B5;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.25;
    letter-spacing: 0.035em;
    border-bottom: 0.05rem solid rgb(240, 242, 245);
}
tbody {
    display: table-row-group;
}
tr, thead {
    color: inherit;
    display: table-row;
    vertical-align: middle;
    outline: 0px;
}
td {
    text-align: left;
    padding: 12px 24px;
    background: transparent;
    box-shadow: none;
    border-bottom: 0.05rem solid rgb(240, 242, 245);
}
td div {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dashboard-content-avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 20px;
}
td span {
    margin: 0px;
    font-size: 0.75rem;
    line-height: 1.25;
    letter-spacing: 0.035em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: gray;
    font-weight: 700;
}
.dashboard-content-icon {
    width: 20px;
    margin-right: 10px;
}
.dashboard-content-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.dashboard-content-footer .pagination {
    padding: 0.5rem;
    margin: 0.2rem;
    border-radius: 5px;
    background-color: #F0F2F5;
    cursor: pointer;
}
.dashboard-content-footer .active-pagination {
    padding: 0.5rem;
    margin: 0.2rem;
    border-radius: 5px;
    color: #FFFFFF;
    background-color: #2D83B5;
    cursor: pointer;
}
.empty-table {
    margin: 0px;
    font-size: 1rem;
    line-height: 1.25;
    letter-spacing: 0.035em;
    color: gray;
    font-weight: 700;
    border-color: #FFFFFF;
}

.blog-text textarea#exampleFormControlTextarea1 {
    width: 100%;
    height: 200px;
}

.blog-btn button{
    padding: 0.8em;
    padding-left: 2em;
    padding-right: 2em;
    cursor: pointer;
    color: #FFFFFF;
    font-size: 0.9rem;
    font-weight: 700;
    border-radius: 8px;
    text-transform: uppercase;
    border: 1px solid #015C92;
    background-color: #015C92;
}
.blog-btn button {
    margin-right: 10px;
    margin-top: 10px;
}

.blog-input input {
    width: 50%;
    border: 1px solid #0000002b;
    margin: 10px;
}
/* Begin of Teams section  */
.teams-add-new-btn {
    text-align: end;
}
.add-new-modal {
    text-align: end;
    padding-top: 30px;
    padding-bottom: 40px;
}
.team-head h3 {
    font-size: 12px;
    color: #000000;
    font-family: inherit;
    font-weight: 600;
    padding: 10px 0px;
}
.team-head input {
    width: 100%;
    border-radius: unset;
    border: 1px solid #00000036;
}
.team-delete i {
    padding: 5px;
}
.team-delete i {
    cursor: pointer;
}
.team-detail-all h3 {
    font-size: 25px;
    font-family: 'Poppins';
    color: #000000;
    padding-top: 10px;
}

.team-detail-all h4 {
    font-size: 60px;
    font-family: 'Poppins';
    font-weight: 600;
    color: #000000;
}
.team-detail-all p {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    /* font-family: monospace; */
    color: #000000;
}
.team-detail-all {
    margin: 20px;
}
/* End of Teams section  */
.sherwoodFrom h2 {
    font-size: 70px;
    font-family: fantasy;
    padding: 0 !important;
    margin: 0;
}
.sherwoodFrom input {
    padding: 10px;
    border-radius: no;
    border-radius: 0px !important;
    margin: 20px 0px;
}
.sherwoodFrom {
    width: 30%;
    margin: 0 auto;
    text-align: center;
}
.sherwoodFrom h4 {
    font-size: 15px;
    font-family: fantasy;
}

.sherwoodFrom button {
    background: #000000;
    border-radius: 0px;
    color: #ffffff;
    cursor: pointer;
}

.sherwoodFrom button.btn:hover {
    color: #ffffff;
    background-color: #000000;
    border-color: aliceblue;
}

/* Begin of BLogshow page css  */
.Blog-dv a {
    padding: 0.8em;
    padding-left: 2em;
    padding-right: 2em;
    cursor: pointer;
    color: #FFFFFF;
    font-size: 0.9rem;
    font-weight: 700;
    border-radius: 8px;
    text-transform: uppercase;
    border: 1px solid #015C92;
    background-color: #015C92;
}
.Blog-dv {
    text-align: end;
    margin-top: 50px;
    margin-bottom: 50px;
}
.blog-table p {
    font-size: 10px;
}
/* End of BLogshow page css  */

table.blog-show-table img {
    width: 22%;
}

table.table-team img {
    width: 100px;
    height: 160px;
    object-fit: cover;
    object-position: top;
}
table.table-team h3 {
    font-size: 15px;
}
table.table-team td i {
    margin: 7px;
    cursor: pointer;
}
.team-detail-all img {
    width: 30%;
}
table.blog-show-table img {
    width: 100px;
    height: 90px;
    object-fit: cover;
    object-position: top;
}
table.contact-table h3 {
    font-size: 11px;
}

/* begin of Dash Css  */
.dash-head h1 {
    font-size: 70px;
    font-family: fantasy;
    text-align: center;
    padding-bottom: 20px;
}
.dash-head {
    padding-top: 38px;
}
/* End of Dash Css  */
.dash-dv {
    background-image: linear-gradient(to right top, #0056b3, #2664b3, #3f72b2, #577faf, #6f8cac);
    padding: 20px;
    border-radius: 10px;
    margin: 10px;
    text-align: center;
    cursor: pointer;
}
.dash-dv h2 {
    font-size: 16px;
    color: #ffffff;
    font-family: 'Poppins';
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
.dash-dv {
    align-self: flex-end;
    background-image: linear-gradient(to right top, #0056b3, #2664b3, #3f72b2, #577faf, #6f8cac);
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: bottom;
    height: 170px;
    /* clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%); */
}

.dash-up:hover {
    animation-name: dash-dv;
    animation-timing-function: ease;
}
.dash-head h4 {
    font-size: 38px;
    font-family: fantasy;
    padding: 0px 10px;
}
@keyframes dash-dv {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(-30px); }
  100% { transform: translateY(0); }
}

.dash-bottom:hover {
    animation-name: dash-dvbottom;
    animation-timing-function: ease;
}
@keyframes dash-dvbottom {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(30px); }
  100% { transform: translateY(0); }
}
.for-dash-blocks {
    padding-top: 0;
}

table-team  tr td:first-child {
    width: 10%;
}

table-team tr td {
    width: 20% !important;
}
table.table-team tr td {
    width: 20%;
    border: 1px solid #0000001f;
    text-align: center;
}

table.table-team tr td:first-child {
    width: 3%;
}

table.table-team tr {
    border-bottom: 1px solid #ddd;
}

.blog-show-table tr td {
    border: 1px solid #0000001f;
    text-align: center;
    width: 60%;
}
th {
    border: 1px solid #0000001f !important;
    text-align: center !important;
}
table.contact-table tr td {
    border: 1px solid #0000001f;
}
table.contact-table tr td:nth-child(8) {
    max-width: 270px;
}
table.contact-table tr td:nth-child(8) h3 {
    font-size: 10px;
    line-height: 15px;
}
.Conatct-head h1 {
    font-size: 66px;
    font-family: auto;
    text-transform: uppercase;
    font-weight: 700;
}
.Conatct-head {
    text-align: center;
    padding: 10px;
}
table.blog-show-table td i {
    margin: 2px;
    cursor: pointer;
}
/* table.contact-table  td {
    
    width: 2%;
    padding: 10px  1px;
    text-align: center;
}
table.contact-table {
    width: 92%;
} */

.contac-table td{
    
    width: 2%;
    padding: 10px  1px;
    text-align: center;
}


.contac-table{
    width: 92% !important;
}
i.fa.fa-trash-o:hover {
    color: #FF5733;
    cursor: pointer;
}
/* ************************************ */

/* Begin of Home css  */
.home-head input {
    border: 1px solid #00000052;
    width: 100%;
    height: 32px;
    border-radius: 6px;
    outline: none;
}
.home-head h2 {
    font-size: 60px;
    font-family: fantasy;
    padding: 20px 0px;
}
.home-head h3 {
    font-size: 16px;
    font-family: 'Poppins';
    padding: 10px 0px;
}
.submit-btn button {
    background-color: #004b77;
    color: #ffffff;
    padding: borde;
    padding: 10px 50px;
    border-radius: 10px;
}
.submit-done {
    padding-top: 17px;
}
table.sevice-table p {
    font-size: 10px;
}
table.sevice-table h5 {
    font-size: 8px;
    color: blue;
}
/* End of Home css  */

/* Begin of service css  */
.all-table-sec p {
    font-size: 10px;
}
.all-table-sec table h3 {
    font-size: 10px;
}

/* Begin of Business Section  */
td.for-extra-content {
    width: 40%;
}
table.table-head td {
    font-size: 12px;
    border: 1px solid #0000001f;
}
table.table-head h3 {
    font-size: 14px;
}
/* End of Business section  */

/* End of service css */
/* Begin of About section  css  */
.about-sumbit {
    margin: 40px 0px;
}
/* End of About section css  */

/* Begin of Other css  */
input[type="file"] {
    background-color: #000000;
    color: #ffffff;
    margin: 10px 6px;
}
input[type="text"] {
    height: 42px;
}
body::-webkit-scrollbar {
    width: 8px;
  }
   
  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
  .home-head h2 {
    text-align: center;
}
/* End of Other Css  */


/* Begin of News Css  */
table.blog-show-table.team-table p {
    font-size: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* End of News Css  */
@media (max-width: 1366px) {
    table.contact-table tr td:nth-child(8) h3 {
        font-size: 10px;
        line-height: 15px;
        word-break: break-word;
        width: 170px;
    }
}

.submit-btn {
    margin-top: 10;
    margin-top: 20px;
}
td.for-small-content {
    font-size: 10px;
    width: 15% !important;
}
thead.th-submit th {
    padding: 13px 4px;
    font-size: 10px;
}
/* td.for-small-contentssss {
    width: 345px !important;
    display: block;
}

td.for-contact-query {
    width: 60px;
    display: block;
} */
.con-table {
    width: 1100px !important;
    display: block;
    overflow: auto;
}